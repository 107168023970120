<template>

    <div style="width:100%;color:#606266">
        <!-- <div style="margin:20px;">

            <h4 style="float:left;color: white;margin-top: 15px;">技术提示 Technical Reminders</h4>
            <el-button type="primary" style="padding:10px;margin-left:20px;margin-top:10px;" @click="showSocialTipClick">{{showSocialTipTitle}}</el-button>
            <br>
            <br>
            <div v-if="showSocialTip" style="color:grey;font-size:12px;padding:10px;background-color:lightGray;float:none">
                <p>Presentation现增设模拟房间，在正式比赛开始前，如你之前错过模拟或在模拟中遇到问题，可登陆新开放的模拟房间进行设备测试。</p>
                <p>Presentation now has additional mock rooms. You can log into the competition system to find the newly opened mock room to test the equipment before the formal competition starts if you missed the mock presentation before or encountered problems in the mock process.</p>


                <p><span style="color:red">请务必在比赛前进行模拟测试</span>，如有问题提前通过邮件/电话联系我们（我们将提供备选方案）。如未进行模拟或由于自身设备有问题但没有提前反馈给FBLA China组委会，在正式比赛时是因为自身设备技术问题导致presentation时长不够，责任由选手自担。FBLA China不会延长比赛时间和提供补考机会。</p>

                <p><span style="color:red">Please make sure to do the mock presentation before the competition</span> and contact us by email/telephone in advance if you have any problems with your equipments (we will provide alternative solutions). FBLA China will not extend the competition time or provide make-up test opportunity under that situation.</p>


                <p>如你在Presentation模拟中遇到问题，请参考以下说明进行操作并重新进入测试：</p>
                <ul style="margin-left: 35px;">
                <li>请使用带有摄像头和麦克风的电脑</li>
                <li>请下载<span style="color:red">最新版本的Chrome谷歌浏览器</span></li>
                <li><span style="color:red">打开浏览器中的摄像头和麦克风权限</span>，确保你的浏览器和网页允许使用电脑摄像头</li>
                <li>如你的电脑扬声器/麦克风有问题，请准备带麦克风的耳机</li>
                <li>如你按照以上操作仍无法成功，请使用其他电脑设备尝试登陆测试</li>
                </ul>

                <p>If you encounter problems during the mock presentation, please refer to the following instructions and re-enter the test.</p>
                <ul style="margin-left: 35px;">
                <li>Please use a computer with a webcam and microphone</li>
                <li>Please <span style="color:red">download the latest version of the Google Chrome browser</span></li>
                <li><span style="color:red">Turn on the camera and microphone permissions in your browser</span> and make sure your browser and web pages allow the use of your computer's camera</li>
                <li>If you have problems with your computer's speakers/microphone, please prepare headphones with microphone</li>
                <li>If you still can't do the presentation by following the above steps, please try another computer device to test</li>
                </ul>
                <p>新增模拟房间比赛期间全程开放，但不设置工作人员在房间内协助，请选手自行登陆进行测试。以下都可正常操作即为模拟成功：</p>

                <p>The new mock room is open to the whole competition period, but no staff is set to assist in the room. Please log in to test by yourself. The mock presentation is successful if the following can be operated normally:</p>
                

                <ul style="margin-left: 35px;">
                <li>可以正常进入会议房间
                You can enter the meeting room normally</li>
                <li>进入后可看到自己的画面、队友的画面
                After entering, you can see your own and your teammates</li>
                <li>可以与队友交流讨论
                You can communicate and discuss with teammates</li>
                <li>可以共享你的屏幕
                You can share your screen</li>
                </ul>
                <p>如你按照以上操作仍无法成功，请小组提前在桌面上下载【腾讯会议】作为备用，并在比赛开始前通过电话/邮件告诉我们，届时你们将通过腾讯会议进行presentation。比赛前我们将发送对应的腾讯会议链接。</p>
                <p>If you still can't present successfully according to the above instructions, please download [Tencent Meeting] on the desktop in advance as a backup, and let us know by phone / email before the competition.  Your team will present through Tencent Meeting to the judges. You will get a Tencent Meeting link before the competition.

</p>
            </div>
        </div> -->
        <div style="padding:20px">
            <!-- <p style="color: white;">会议列表</p> -->
            <el-table :data="roomList">
                <el-table-column label="项目" prop="event" width="200"></el-table-column>
                <el-table-column label="准备开始时间" prop="prep_start_time"></el-table-column>
                <el-table-column label="准备结束时间" prop="prep_end_time"></el-table-column>
                <el-table-column label="正式演示时间" prop="prez_start_time"></el-table-column>
                <el-table-column label="房间号" prop="room" width="100"></el-table-column>
                <el-table-column label="腾讯会议ID" prop="meeting_room_code"></el-table-column>
                <el-table-column label="类型" prop="team_type" width="100"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="toView(scope.row._id.$id,scope.row.room,'',scope.row.team_type,scope.row.event)">进入准备室</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {getUserId,getProjectCode} from '../../utils/store'
import {formatDate} from '../../utils/date'
import {getPresentationRoomsByApplication,checkPresentationRoomOpen} from '../../api/index'
export default {
    data(){
        return{
            roomList:[],
            open_status:[],
            showSocialTip:true,
            showSocialTipTitle:'收起 Fold'
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            let student_id = getUserId()
            getPresentationRoomsByApplication(student_id,getProjectCode()).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.roomList=res.data.data
                }
            })
        },
        formatDate(time){
            let date = new Date(time*1000)
            return formatDate(date,'Y-MM-dd hh:mm:ss')
        },
        toView(room_id,roomId,application_id,type,event_name){
            this.checkPrezRoomOpen(room_id,roomId,application_id,type,event_name)

            
            // this.$router.push({
            //     path:'/discussing',
            //     query:{
            //         roomId:roomId,
            //         application_id:application_id,
            //         event_name:event_name
            //     }
            // })


            
        },
        checkPrezRoomOpen(room_id,roomId,application_id,type,event_name){


            
            checkPresentationRoomOpen(room_id).then(res=>{
                


                var open_data  = res.data

            // console.log(open_data)

                if (open_data.code == 0) {


                    // if(type=='Preparation'){
                        this.$router.push({
                            path:'/discussing',
                            query:{
                                roomId:roomId,
                                application_id:application_id,
                                event_name:event_name
                            }
                        })
                    // }else{
                    //     this.$router.push({
                    //         path:'/student/meeting',
                    //         query:{
                    //             roomId:roomId,
                    //             application_id:application_id
                    //         }
                    //     })
                    // }
                }else{

                    //TODO - 提示错误信息
                    this.$message(open_data.msg)
                }
                

            })
            
        },
        echo(){

            console.log('echo')
        },
        showSocialTipClick(){
          if(this.showSocialTip == true){
             this.showSocialTip = false;
             this.showSocialTipTitle = '展开 Unfold';
          }else{
             this.showSocialTip = true;
             this.showSocialTipTitle = '收起 Fold';
          }
        }
    }
}
</script>

<style>

</style>
